import React, { useRef } from 'react';
import { chipImg, frameImg, frameVideo } from '../utils';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { animateWithGsap } from '../utils/animations';

const HowItWorks = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.from('#chip', {
      scrollTrigger: {
        trigger: '#chip',
        start: '20% bottom'
      },
      opacity: 0,
      scale: 2,
      duration: 2,
      ease: 'power2.inOut'
    });

    animateWithGsap('.g_fadeIn', {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power2.inOut'
    });
  }, []);

  return (
    <section className="common-padding">
      <div className="screen-max-width">
        <div id="chip" className="flex-center w-full my-20">
          <img src={chipImg} alt="chip" width={180} height={180} />
        </div>

        <div className="flex flex-col items-center">
          <h2 className="hiw-title">
            Use AI to empower every person in the world.
            <br /> 
            <br /> Trust humanity.
          </h2>

          <p className="hiw-subtitle">
            It's here. The biggest redesign in the history of the world. And you can be part of it.
          </p>
        </div>

        <div className="mt-10 md:mt-20 mb-14">
          <div className="relative flex-center" style={{ width: 'fit-content', margin: '0 auto' }}>
            <div className="relative" style={{ width: 'fit-content' }}>
              <img 
                src={frameImg}
                alt="frame"
                className="bg-transparent relative z-10"
                style={{ display: 'block' }}
              />
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center z-0" style={{ padding: '2%' }}>
                <video 
                  className="pointer-events-none object-cover" 
                  style={{ width: '96%', height: '96%' }} // Hacemos el video un poco más pequeño
                  playsInline 
                  preload="none" 
                  muted 
                  autoPlay 
                  ref={videoRef}
                >
                  <source src={frameVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <p className="text-gray font-semibold text-center mt-3">AI Generated video with Luma AI</p>
        </div>

        <div className="hiw-text-container">
          <div className="flex flex-1 justify-center flex-col">
            <p className="hiw-text g_fadeIn">
              AI will change every organization,{' '}
              <span className="text-white">improving performance by far on those using it.</span>
            </p>
<br />
            <p className="hiw-text g_fadeIn">
              New models{' '}
              <span className="text-white">will look and feel so immersive</span>,
              with incredibly detailed environments and characters.
            </p>
          </div>

          <div className="flex-1 flex justify-center flex-col g_fadeIn">
            <p className="hiw-text">Get ready</p>
            <p className="hiw-bigtext">For the AI Era</p>
            <p className="hiw-text">Book a call today.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
